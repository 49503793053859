(($) => {

	/**
	 * Gulp include scripts
	 *
	 * @link https://www.npmjs.com/package/gulp-include
	 */
	//=require ../../template-parts/blocks/example/block-example.js

	/**
	 * jQuery Document Ready
	 *
	 * @link https://learn.jquery.com/using-jquery-core/document-ready/
	 */
	$(() => {
		$('.hamburger').click(function(){
			let status = $(this).attr('aria-expanded');
			if (status == 'true') {
				$(this).attr('aria-expanded', false);
				$(this).siblings('.mobile-menu').removeClass('active');
			} else {
				$(this).attr('aria-expanded', true);
				$(this).siblings('.mobile-menu').addClass('active');
			}
		});

		$('.js-mobile-sublinks').click(function(){
			let parent =  $(this).closest('li');
			if (parent.hasClass('active')) {
				parent.removeClass('active');
			} else {
				parent.siblings().removeClass('active');
				parent.addClass('active');
			}
		});

		$('.js-sublinks').hover(
			function() {
				$(this).addClass('active');
			}, function() {
				$(this).removeClass('active');
			}
		);

        $('.js-popup-team').click(function(e){
            e.preventDefault();
            let memberId = '#member-'+$(this).data("member");
            if ($(memberId).length > 0) {
                $(memberId).css('display', 'flex');
                $('body').css("overflow", "hidden");
            }
        });

        $('.js-btn-close').click(function(e){
            $('body').css("overflow", "auto");
            $(this).closest('.popup-team').hide();
        });

		$('.js-accordion-toggle').click(function(){
			let item = $(this).closest('li');
			if (item.hasClass('active')) {
				item.removeClass('active');
			} else {
				item.addClass('active');
			}
		});

        if ($('.cards-slider').length) {
            $('.cards-slider').slick({
                infinite: true,
                slidesToShow: 4,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 3000,
                prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"></button>',
                nextArrow: '<button type="button" class="slick-next" aria-label="Next"></button>',
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }
                    }
                ]
            });
        }

        if ($('.marquee-slider').length) {
            $('.marquee-slider').slick({
                arrows: false,
                infinite: true,
                slidesToShow: 8,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 0,
                speed: 8000,
                pauseOnHover: false,
                cssEase: 'linear',
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                        }
                    }
                ]
            });
        }

		if ($('.testimonial-slider').length) {
			$('.testimonial-slider').slick({
				dots: true,
				infinite: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"></button>',
				nextArrow: '<button type="button" class="slick-next" aria-label="Next"></button>',
			});
		}
	});

    jQuery(document).on('gform_post_render', function(event, form_id, current_page){
        // Inline label forms: Add active class if input has value
        $('.js-gform input[type=text], .js-gform input[type=tel], .js-gform input[type=email], .js-gform input[type=number], .js-gform textarea').each(function () {
            if ($(this).val().length) {
                $(this).closest('.gfield').addClass('active');
            }
        });

        $('.js-gform select').on('change', function() {
            if ($(this).val() === "") {
                $(this).closest('.gfield').removeClass('active');
            } else {
                $(this).closest('.gfield').addClass('active');
            }
        });

        // Inline label forms: Add active class on focus
        $('.js-gform input[type=text], .js-gform input[type=tel], .js-gform input[type=email], .js-gform input[type=number], .js-gform textarea').on('focus', function () {
            $(this).closest('.gfield').addClass('active');
        });

        // Inline label forms: Remove active class on blur
        $('.js-gform input[type=text], .js-gform input[type=tel], .js-gform input[type=email], .js-gform input[type=number], .js-gform textarea').on('blur', function () {
            if (!$(this).val().length || $(this).val().includes('_')) {
                $(this).closest('.gfield').removeClass('active');
            }
        });
    });

})(jQuery);
